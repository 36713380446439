/* ------------------Creator card----------------- */
.CreatorcardCss {
  min-height: 300px;
}
.CreatorcardDiv {
  justify-content: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
}

.Creatorcard {
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  transition: 0.5s ease-in-out;
}
.Creatorcard:hover {
  transform: translateY(20px);
  background-color: rgb(140, 197, 235);
}
.creatorImg {
  margin: 0;
  padding: 0;
  display: grid;
  min-height: 300px;
}
.contactIcona .contactIcon {
  color: rgb(13, 13, 112);
  float: left;
  transition: 0.6s ease-out;
}
.contactIcona .contactIcon:hover {
  transform: rotateZ(40deg);
  color: rgb(125, 144, 231);
}
.contactIcon a:hover {
  color: aqua;
}
