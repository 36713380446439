* {
  margin: 0;
  padding: 0;
}

.homediv {
  margin: 0;
  padding: 0;
}
/* --------------Banner Text--------------- */
.bannerMainTitle h1 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 70px;
}

/* ---------put cursor--------------- */
.hlwhereText .PutCursorTitle {
  position: relative;
  z-index: 3;
  color: #eb4d4dd7;
  opacity: 1;

  transform: translateY(0px);
  transition: 0.5s all;
}
.hlwhereText:hover .PutCursorTitle {
  opacity: 0;
  transform: translateY(50px);
}
/* ------------------------------------ */
.hlwhereText .PutCursorContent {
  position: relative;
  z-index: 3;
  color: #fdffff;
  opacity: 0;

  transform: translateY(0px);
  transition: 0.5s all;
}
.hlwhereText:hover .PutCursorContent {
  opacity: 1;
  transform: translateY(30px);
}

/* -----------topic card---------- */
.cardCss {
  min-height: 300px;
}
.cardDiv {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: grid;
  grid-gap: 5rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  min-height: 300px;
}

.card {
  min-height: 300px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  transition: 0.5s ease-in-out;
}
.card:hover {
  transform: translateY(20px);
  background-color: rgb(166, 202, 226);
}
.cardLink {
  cursor: pointer;
  text-decoration: none;
  color: rgb(9, 65, 221);
}

.wrapper {
  display: flex;
}
.wrapper .staticText {
  color: #130202;
  font-size: 30px;
  font-weight: 200px;
}
.wrapper .dynamicText {
  margin-left: 15px;
  height: 80px;
  line-height: 30px;
  overflow: hidden;
}
.dynamicText {
  list-style: none;
  color: #081fa5;
  font-size: 25px;
  font-weight: 250px;

  position: relative;
  top: 10;
  animation: slide 10s steps(1) infinite;
}
@keyframes slide {
  100% {
    top: -360px;
  }
}
.dynamicText li span {
  position: relative;
  margin: 5px 0;
  line-height: 25px;
}

.dynamicText li span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;

  background: #fff;
  border-left: 2px solid #141313;
  animation: typing 10s steps(20) infinite;
}

.bannerimg {
  background-image: url("../../../../public/image/banner.png");
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center;
}

@keyframes typing {
  40%,
  60% {
    left: calc(100% + 30px);
  }
  100% {
    left: 0;
  }
}
