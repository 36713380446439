.navberr {
  background-color: rgba(7, 79, 168, 0.76);
  border: none !important;
}

.nav {
  text-indent: 50px;
  font-size: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.navlinks {
  text-decoration: none;
  color: #fff;
}
.navlinks:hover {
  color: rgb(65, 7, 7);
}
.active {
  color: rgb(65, 7, 7);
}
